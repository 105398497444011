<!-- ########################## <Template> ########################## -->
<template>
<Toast position="top-left" group="tl" />
        <!-- <P-Button icon="k-icon k-i-formula-fx" class="p-button-rounded p-button-lg" @click="fnFormuleInfoOpen" style="fontSize: 3rem;position: fixed;bottom:60px;right:40px;z-index:1000;cursor:pointer;width: 50px;height: 50px;"/> -->
        <div class="panel panel-default">
            <div class="panel-heading">
                
                <kendo-button icon="add"  @kendoclick="fnAddIndicateur">ajouter un nouvel indicateur financier</kendo-button>
                &nbsp;
                <!-- <kendo-button icon="formula-fx" @kendoclick="fnFormuleInfoOpen">aide formule</kendo-button> -->
            </div>
            <div class="panel-body" >
                <!--  -->
                    <SlickList axis="y" v-model:list="listeIndicateurs" class="long-list" @update:list="onSortEnd" :useDragHandle="true">
                        <SlickItem v-for="(indicateur, i) in listeIndicateurs" :key="indicateur.id" :index="i">
                            <ClientSettingsIF2Element 
                                    :key="indicateur.id"
                                    :indicateurId="indicateur.id"
                                    :libelleIndicateur="indicateur.libelleIndicateur"
                                    :formule="indicateur.formule"
                                    :clientId="indicateur.clientId"
                                    :ordre="indicateur.ordre"
                                    @OpenIF2 = "fnFormuleInfoOpen"
                                    @indicateurDeleted="onIndicateurDeleted"
                            >
                            </ClientSettingsIF2Element>
                        </SlickItem>
                    </SlickList>
                <!--  -->
            </div>
        </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>

    
    <Sidebar v-model:visible="showFormuleInfo" position="right" class="p-sidebar-md" :modal="false">
        <template #header>
            <div>
                <i class="pi pi-question-circle"></i> Aide formule
            </div>
        </template> 
        <div class="divPanel" style="font-size:12px;background-color: #f0f3fd;">
                        <p>
                        La formule des indicateurs financiers se base sur une ou deux années. 
                        Pour accéder aux propriétés de la dernière année saisie, il faut utiliser le préfix N. 
                        Pour accéder aux propriétés de la deuxième dernière année, il faut utiliser le préfix N1.
                        </p>
                        
                        <p>Les champs du bilan financier sont:</p>
                        <kendo-tabstrip ref="refKendoTabStrip" >
                        <ul>
                            <li class="k-state-active tabStribHeader" style="vertical-align:middle;border-bottom-color: #0d6efd;border-width: 2px;width: 33%; font-size: 13px;">
                                <!-- <span class="k-icon k-i-greyscale"></span> -->
                                    Actif
                            </li>
                            <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #f31700;border-width: 2px;width: 33%; font-size: 13px;">
                                    <!-- <span class="k-icon k-i-dollar"></span> -->
                                    Passif
                            </li>
                            <li class="tabStribHeader" style="vertical-align:middle;border-bottom-color: #37b400;border-width: 2px;width: 33%; font-size: 13px;">
                                    <!-- <span class="k-icon k-i-saturation"></span> -->
                                    CPC
                            </li>
                        </ul>

                        <div v-for="(bilanType,index) in bilanTypes" :key="index">
                            <div :class="'bilan'+bilanType" style="font-size: 12px;">
                                <table>
                                    <tr>
                                        <th style="width:85%"></th>
                                        <th style="width:50px">N</th>
                                        <th style="width:50px">N-1</th>
                                    </tr>
                                    <tr v-for="property in formuleInfo.filter(function(d){
                                        return d.bilanType == bilanType && d.estCopiable==1
                                    })" :key="property.id">
                                        <td style="border:solid 1px lightgrey;" :style="property.style" :tooltip="property.codeTechnique" >{{property.nom}}</td>
                                        <td style="border:solid 1px lightgrey;text-align: center;cursor:pointer" :key="property.codeTechnique" flow="left" :tooltip="'N.'+property.codeTechnique"  ><i @click="fnCopy('N.'+property.codeTechnique)" class="pi pi-copy" ></i></td>
                                        <td style="border:solid 1px lightgrey;text-align: center;cursor:pointer" :key="property.codeTechnique" flow="left" :tooltip="'N1.'+property.codeTechnique" ><i @click="fnCopy('N1.'+property.codeTechnique)" class="pi pi-copy" ></i></td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                        
                        </kendo-tabstrip>

                        
                        <!-- <div class="row">
                            <div class="col-6" >
                                <ul>
                                    <li @click="fnCopy('N.'+property.codeTechnique)" v-for="property in formuleInfo" :key="property.codeTechnique"  :style="property.style" >{{property.nom}} </li>
                                </ul>
                            </div>
                            <div class="col-6">
                                <ul>
                                    <li @click="fnCopy('N1.'+property.codeTechnique)" v-for="property in formuleInfo" :key="property.codeTechnique"  :style="property.style" >{{property.nom}} </li>
                                </ul>
                            </div>
                        </div> -->

                        <p>Exemple: (N.ca - N1.ca) /  (N.cashEtEquivalent * N1.cashEtEquivalent) / 100</p>
                        <p>Il est possible d’utiliser des conditions Si condition alors formule1 sinon formule2 :<br>
                        (N1.ca  &lt; N.ca) ? N.ca : N1.stock</p>
                        <p>La bibliothèque Math permet d'utiliser des operations mathématiques. Ex : Math.min([N.ca],[N1.ca]) <a targer="_blank" href="https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Math">plus d'info</a></p>
                        <p>La bibliothèque Date permet d'utiliser des operations sur les dates. Ex : (new Date(N.dateBilan).getFullYear()) <a targer="_blank" href="https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Global_Objects/Date">plus d'info</a></p>
                        
                    
                    </div>
                    <div class="text-right">
                        <button type="button" class="k-button" @click="fnClose">Fermer</button>
                    </div>
                <div>{{errorMessage}}</div>
    </Sidebar>
    
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsIF2Element from "./ClientSettingsIF2Element.vue";
import {_} from 'vue-underscore';
import { setTransitionHooks } from '@vue/runtime-core';
import { SlickList, SlickItem } from 'vue-slicksort';
import formuleTestData from './FormuleTestData.json';
import kendo from '@progress/kendo-ui'
import Sidebar from 'primevue/sidebar';
import { ref, watchEffect } from 'vue'
import { useClipboard } from 'vue-reactive-clipboard'
import { TabStrip } from '@progress/kendo-layout-vue-wrapper';

const { text, copy   } = useClipboard()



export default {
    name: "ClientSettingsIF",
    //--------------------------- Components --------------------------
    components: {
       'kendo-button': Button,
        ClientSettingsIF2Element,
        SlickList,
        SlickItem,
        Sidebar,
        'kendo-tabstrip': TabStrip,
        
        
    },
    //---------------------------- props ------------------------------
    props:   ['clientId','selectedClient'],
    //---------------------------- emits ------------------------------
    emits: ["echelleChanged"],
    //------------------------------ Data -----------------------------
    data() {
        return {
           
            errorMessage : "",
            showFormuleInfo:false,
            formuleInfo:[],
            bilanTypes:["Actif","Passif","CPC"]
        }

    }, //end data()
    computed: {
        listeIndicateurs()
        {
            this.log("@@@@ listeIndicateurs ..." + this.$store.state.clientSettingsStoreModule.IFsList.length);
            return this.$store.state.clientSettingsStoreModule.IFsList;
        },
        formuleTestDataProperties()
        {
            var properties = Object.getOwnPropertyNames(formuleTestData.N);
            this.log(formuleTestData.N);
        
            return properties;
        }
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsIF");
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("======================================================= mounted() => ClientSettingsIF");
        this.fnLoadAideFormule();
        this.fnLoadIndicateurs();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsIF");
    },

    //-------------------------------- Methods --------------------------
    methods: {
        fnCopy(v) {
            console.log(v);
            copy(v);

            this.$toast.add({severity: 'success', summary: '', detail: '"'+v+'" copié dans le presse-papier. Ctrl+V pour l\'utiliser', group: 'tl', life: 3000});
        },
        fnClose()
        {
            this.showFormuleInfo = false;
        },
        async fnLoadAideFormule()
        {
            var response = await CRUDService.list('BilanListeChamps')
            if(response.data)
            {
                // 
                this.formuleInfo = response.data
            }
            else
            {
                alert(response.data)
            }
        },
        fnLoadIndicateurs()
        {
            // we need to change the webapi endpoint for ClientUser / AdminUser for better security
            var endpoint = this.isAdminApplication?'indicateursfinanciersadmincustom':'indicateursfinanciersclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
                (response) => {
                    this.log("----------------- fnLoadIndicateurs reponse");
                    this.log(response.data);
           
                    for (let index = 0; index < response.data.length; index++) {
                        const element = response.data[index];
                        element.note=-1;
                        element.valeur=-1;
                        this.$store.commit('clientSettingsStoreModule/updateIFsList', element);
                    }
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            );
        },
        fnAddIndicateur()
        {
            this.log("fnAddIndicateur");
            var endpoint = this.isAdminApplication?'indicateurfinancier':'indicateurfinancierclient';
            
            var nouveauIndicateur = { clientId: this.clientId,
                                    libelleIndicateur: "Indicateur financier "+(this.listeIndicateurs.length+1),
                                    formule: "",
                                    ordre: this.listeIndicateurs.length,
                                    echelleIndicateur: 5};
            CRUDService.create(nouveauIndicateur,endpoint).then(
                (response) => {
                    this.log(response.data);
                    //this.listeIndicateurs.push(response.data);
                    this.$store.commit('clientSettingsStoreModule/updateIFsList', response.data);
                    setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight? document.body.scrollHeight : document.documentElement.scrollHeight);
                    }, 200); 
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        },
        fnFormuleInfoOpen()
        {
            this.log("fnFormuleInfoOpen");
            this.showFormuleInfo = true;
        },
        fnFormuleInfoClose()
        {
            this.showFormuleInfo = false;
        },
        onIndicateurDeleted(deletedId)
        {
            this.log("onIndicateurDeleted: "+deletedId);
            for( var i = 0; i < this.listeIndicateurs.length; i++){ 
                if ( this.listeIndicateurs[i].id === deletedId) { 
                    this.listeIndicateurs.splice(i, 1); 
                }
            }
        },
        onSortEnd(sortedList)
        {
            this.log("onSortEnd");
            //;
            for (let index = 0; index < sortedList.length; index++) {
                const element = sortedList[index];
                this.log("    => updateordre");
                var indicateurToUpdate = { id: element.id, ordre: index};
                this.log(indicateurToUpdate);
                var endpoint = this.isAdminApplication?'indicateurfinancier/updateordre':'indicateurfinancierclient/updateordre';
                CRUDService.update(indicateurToUpdate,endpoint).then(
                    (response) => {
                        this.log(response.data);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            }
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.long-list {
  max-height: 12000px;
  overflow: auto;
}
    
.inputNote{
    width:70px;
}

#abdc94ab-61df-4ad1-b20c-75a2c6ef0965-2 > button > span.k-icon.k-i-formula-fx.p-button-icon
{font-size:1.5rem;}

.tabStribHeader>span {
    margin-top: 0px !important;
}

.p-sidebar-header-content {
    width: 100% !important;
}

.bilanActif .pi-copy{
    color: #0d6efd;
}

.bilanPassif .pi-copy{
    color: #f31700;
}

.bilanCPC .pi-copy{
    color: #37b400
;
}

</style>