<!-- ########################## <Template> ########################## -->
<template>
        <div class="panel panel-default">
            <div class="panel-heading">
                <kendo-button icon="add"  @kendoclick="fnAddIndicateur">ajouter un nouvel indicateur qualitatif</kendo-button>
            </div>
            <div class="panel-body" >
                <!--  -->
                    <SlickList  axis="y" v-model:list="listeIndicateurs" class="long-list" @update:list="onSortEnd" :useDragHandle="true">
                        <SlickItem v-for="(indicateur, i) in listeIndicateurs" :key="indicateur.id" :index="i">
                            
                            <ClientSettingsIQ2Element 
                                    :indicateurId="indicateur.id"
                                    :libelleIndicateur="indicateur.libelleIndicateur"
                                    :clientId="indicateur.clientId"
                                    :ordre="indicateur.ordre"
                                    @indicateurDeleted="onIndicateurDeleted"
                            >
                            </ClientSettingsIQ2Element>

                        </SlickItem>
                    </SlickList>
                <!--  -->
            </div>
        </div>
    <div class="errorMessage" ref="errorMessage"> {{errorMessage}}</div>
</template>
<!-- ########################## <Script> ########################## -->
<script>

import CRUDService from "../../1-common-components/crud.service";
import { Button } from '@progress/kendo-buttons-vue-wrapper';
import ClientSettingsIQ2Element from "./ClientSettingsIQ2Element.vue";
import {_} from 'vue-underscore';
import { SlickList, SlickItem } from 'vue-slicksort';
import { setTransitionHooks } from '@vue/runtime-core';



export default {
    name: "ClientSettingsIQ",
    //--------------------------- Components --------------------------
    components: {
        'kendo-button': Button,
        ClientSettingsIQ2Element,
        SlickList,
        SlickItem
    },
    //---------------------------- props ------------------------------
    props:   ['clientId','selectedClient'],
    //---------------------------- emits ------------------------------
    emits: ["echelleChanged"],
    //------------------------------ Data -----------------------------
    data() {
        return {
            errorMessage : ""
        }

    }, //end data()
    computed: {
        listeIndicateurs()
        {
            return this.$store.state.clientSettingsStoreModule.IQsList;
        },
    },
    //------------------------------- Created --------------------------
    created() {
        this.log("----------- created() => ClientSettingsIQ");
    },
    //------------------------------ Mounted ---------------------------
    mounted() {
        this.log("======================================================= mounted() => ClientSettingsIQ1Component");
        this.fnLoadIndicateurs();
    }, //end mounted
    //------------------------------- activated --------------------------
    activated() {
        this.log("----------- activated() => ClientSettingsIQ");
    },

    //-------------------------------- Methods --------------------------
    methods: {


        fnLoadIndicateurs()
        {
            // we need to change the webapi endpoint for ClientUser / AdminUser for better security
            var endpoint = this.isAdminApplication?'indicateursqualitatifsadmincustom':'indicateursqualitatifsclientcustom';
            
            CRUDService.get(this.clientId, endpoint).then(
            (response) => {
                this.log("----------------- fnLoadIndicateurs reponse");
                this.log(response.data);
                for (let index = 0; index < response.data.length; index++) {
                    const element = response.data[index];;
                    this.$store.commit('clientSettingsStoreModule/updateIQsList', element);
                }
            },
            (error) => {
                this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
            }
            );
        },
        fnAddIndicateur()
        {
            this.log("fnAddIndicateur");
            var endpoint = this.isAdminApplication?'indicateurqualitatif':'indicateurqualitatifclient';
            
            var nouveauIndicateur = {   clientId: this.clientId,
                                        libelleIndicateur: "Indicateur qualitatif "+(this.listeIndicateurs.length+1),
                                        ordre: this.listeIndicateurs.length,
                                        echelleIndicateur: 5
                                    };
            CRUDService.create(nouveauIndicateur,endpoint).then(
                (response) => {
                    this.log(response.data);
                    //this.listeIndicateurs.unshift(response.data);
                    // this.listeIndicateurs.push(response.data);
                    this.$store.commit('clientSettingsStoreModule/updateIQsList', response.data);

                    setTimeout(() => {
                        window.scrollTo(0, document.body.scrollHeight? document.body.scrollHeight : document.documentElement.scrollHeight);
                    }, 200); 
                },
                (error) => {
                    this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                }
            ); 
        },
        onIndicateurDeleted(deletedId)
        {
            this.log("onIndicateurDeleted: "+deletedId);
            for( var i = 0; i < this.listeIndicateurs.length; i++){ 
                if ( this.listeIndicateurs[i].id === deletedId) { 
                    this.listeIndicateurs.splice(i, 1); 
                }
            }
        },
        onSortEnd(sortedList)
        {
            this.log("onSortEnd");
            //;
            for (let index = 0; index < sortedList.length; index++) {
                const element = sortedList[index];
                this.log("    => updateordre");
                var indicateurToUpdate = { id: element.id, ordre: index};
                this.log(indicateurToUpdate);
                var endpoint = this.isAdminApplication?'indicateurqualitatif/updateordre':'indicateurqualitatifclient/updateordre';
                CRUDService.update(indicateurToUpdate,endpoint).then(
                    (response) => {
                        this.log(response.data);
                    },
                    (error) => {
                        this.errorMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
                    }
                ); 
            }
        }

    }
    //-------------------------------------------------------------------
} //end export default
</script>

<!-- ############################### <Style> ############################ -->
<style>
  
.long-list {
  max-height: 12000px;
  overflow: auto;
}

.handle {

  width: 18px;
  height: 18px;
  background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z" color="black"></path></svg>');
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
}
    
</style>